import axios from "axios";
import store from "../../redux/store";
import { setEncompassToken } from "../../redux/auth/authSlice";

export class EncompassApi {
	constructor(token) {
		this.axios = axios.create();
		this.baseUrl = "https://api.elliemae.com";
		this.token = token;
		this.axios.interceptors.request.use(
			async (config) => {
				// if (!this.token.access_token) {
				// 	// Get the token from react state
				// 	this.token = await this.getToken();
				// }
				config.headers = {
					"Authorization": `Bearer ${this.token}`,
					"Accept": "application/json",
					"Content-Type": "application/json",
				};
				return config;
			},
			(error) => {
				console.log("Error in Request");
				Promise.reject(error);
			}
		);
		this.axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				const originalRequest = error.config;
				console.log('Error trying to reconnect=>', { error });
				if (error.response.status === 401) {
					console.log('attempting reconnect');
					let refreshToken = await this.getEncompassAccessToken(originalRequest);
					console.log('token?', refreshToken);
					this.token = refreshToken;
					this.axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
					let retry = await this.axios(originalRequest);
					return (retry);
				} else {
					return error.response;
				}
			}
		);
	}

	async getEncompassAccessToken() {
		//call the local host..
		console.log('getting port');
		let state = store.getState();
		console.log('state', state);
		let port = state.encompass.port;
		console.log('port', port);
		try {
			let result = await axios.get(`http://localhost:${port}/getAuthToken/`);
			//this.token = result.data;
			store.dispatch(setEncompassToken(result.data));
			console.log(result.data);
			return result.data;
		} catch (ex) {
			console.log(ex);
		// 	return (ex);
		}
	}

	async getLoanFolders() {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v3/loanFolders`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async getLoanPipelineView(view, viewId) {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v3/loanPipeline/views/${view}/${viewId}`);
			return (result.data);
		} catch (ex) {
			return (ex);
		}
	}

	async getPipelineViews() {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v3/loanPipeline/views`);
			return (result.data);
		} catch (ex) {
			return (ex);
		}
	}

	async loanPipelineQuery(query, limit = 4000, start = 0, data = [], cursor = '') {
		let params = { start: start, limit: limit, cursorType: 'randomAccess' };
		if (cursor) {
			if (params.cursorType) delete params.cursorType;
			params.cursor = cursor;
			if (query.filter) delete query.filter;
		}

		let config = {
			method: 'post',
			params: params,
			url: `${this.baseUrl}/encompass/v3/loanPipeline`,
			data: query,
		};

		let result = await this.axios(config);

		if (result.status != 200) {
			throw new Error(`${result.data.summary}: ${result.data.details}`);
		}

		let totalCount = result.headers['x-total-count'];
		let xcursor = result.headers['x-cursor'] ?? '';
		data.push(...result.data);

		if (data.length < totalCount) {
			return await this.loanPipelineQuery(query, limit, start + limit, data, cursor);
		}

		return data;
	}

	async getUserList(limit = 5000, start = 0, data = []) {
		let params = {start: start, limit: limit}
		let config = {
			method: 'get',
			url: `${this.baseUrl}/encompass/v1/company/users/`,
			params: params
		};
		let result = await this.axios(config);
		if (result.status != 200) {
			throw new Error(`${result.data.summary}: ${result.data.details}`);
		}
		let totalCount = result.headers['x-total-count'];
		let xcursor = result.headers['x-cursor'] ?? '';
		data.push(...result.data);

		if (data.length < totalCount) {
			return await this.getUserList(limit, start + limit, data);
		}

		return data;
	}

	async loanPipelineQueryNoCursor(query) {
		let config = {
			method: 'post',
			url: `${this.baseUrl}/encompass/v3/loanPipeline`,
			data: query,
		};
		try {
			let result = await this.axios(config);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async getUserGroups() {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v1/settings/userGroups`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async getAllTasks(options) {
		let config = {
			method: 'get',
			params: options,
			url: `${this.baseUrl}/workflow/v1/tasks`,
		};
		let result = await this.axios(config);
		console.log('Data=>', result.data);
		if (result.status != 200) {
			throw new Error(`${result.data.summary}: ${result.data.details}`);
		}
		return result;
	}

	async getTaskPipeline(options) {
		let config = {
			method: 'get',
			params: options,
			url: `${this.baseUrl}/workflow/v1/taskPipeline`,
		};
		let result = await this.axios(config);
		if (result.status != 200) {
			throw new Error(`${result.data.summary}: ${result.data.details}`);
		}
		return result;
	}

	async getTaskSettings() {
		try {
			let result = await this.axios.get(`${this.baseUrl}/workflow/v1/settings/task`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async updateTask(id, options, subtask) {
		let params;
		let data;
		let url;
		console.log('encapisub', subtask);
		if (subtask) {
			url = `${this.baseUrl}/workflow/v1/tasks/${id}/subtasks/${subtask}`
		} else { 
			url = `${this.baseUrl}/workflow/v1/tasks/${id}`
		}
		if (options.view) {
			params = { view: options.view }
		} 
		if (options.body) {
			data = options.body;
		}
		let config = {
			method: 'patch',
			params: params,
			url: url,
			data: data,
		};
		try {
			let result = await this.axios(config);
			console.log('result=>', result);
			return (result.data);
		} catch(ex) {
			return (ex);
		}
	}

	async getMe() {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v1/company/users/me`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async getSubTasks(id) {
		try {
			let result = await this.axios.get(`${this.baseUrl}/workflow/v1/tasks/${id}/subtasks`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}

	async createUpdateGlobalCdo(name, object) {
		let data = btoa(JSON.stringify(object));
		let body = {
			dataObject: data
		};
		let config = {
			method: 'put',
			url: `${this.baseUrl}/encompass/v1/company/customObjects/${name}`,
			data: body,
		};
		try {
			let result = await this.axios(config);
			return result;
		} catch (errors) {
			return errors;
		}
	};

	async getGlobalCdo(name) {
		try {
			let result = await this.axios.get(`${this.baseUrl}/encompass/v1/company/customObjects/${name}`);
			return result.data;
		} catch (ex) {
			return (ex);
		}
	}
}