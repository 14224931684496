import React from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme, getTheme } from '../redux/theme/themeSlice';
import { THEMES } from '../constants';
import { green, grey, indigo } from '@mui/material/colors';

import { Box, Drawer, Grid, Typography, ListItemButton } from '@mui/material';

import { Alert } from '@mui/lab';

const DemoButton = styled('div')`
	cursor: pointer;
	background: ${(props) => props.theme.palette.background.paper};
	height: 80px;
	border-radius: 0.3rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.825rem;
	position: relative;
	border: 1px solid
		${(props) =>
			!props.active
				? props.theme.palette.action.selected
				: props.theme.palette.action.active};
`;

const DemoButtonInner = styled('div')`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
	position: relative;

	${(props) =>
		props.selectedTheme === THEMES.DEFAULT &&
		`
			background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
		`}
	${(props) =>
		props.selectedTheme === THEMES.DARK &&
		`
			background: #23303f;
		`}
  ${(props) =>
		props.selectedTheme === THEMES.LIGHT &&
		`
			background: ${grey[100]};
		`}
  ${(props) =>
		props.selectedTheme === THEMES.BLUE &&
		`
			background: linear-gradient(-45deg, #4782da 50%, ${grey[100]} 0);
		`}
  ${(props) =>
		props.selectedTheme === THEMES.GREEN &&
		`
			background: linear-gradient(-45deg, ${green[500]} 50%, ${grey[100]} 0);
		`}
  ${(props) =>
		props.selectedTheme === THEMES.INDIGO &&
		`
			background: linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0);
		`}
`;

const DemoTitle = styled(Typography)`
	text-align: center;
`;

const Wrapper = styled('div')`
	width: 258px;
	overflow-x: hidden;
`;

const Heading = styled(ListItemButton)`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;

	${(props) => props.theme.breakpoints.up('sm')} {
		min-height: 64px;
	}
`;

function Demo({ title, theme }) {
	const dispatch = useDispatch();
	const currentTheme = useSelector(getTheme);

	return (
		<Grid item xs={6}>
			<DemoButton
				active={theme === currentTheme}
				onClick={() => {
					dispatch(setTheme(theme));
				}}>
				<DemoButtonInner selectedTheme={theme} />
			</DemoButton>
			<DemoTitle variant='subtitle2' gutterBottom>
				{title}
			</DemoTitle>
		</Grid>
	);
}

function Demos() {
	return (
		<Wrapper>
			<Heading button={true}>Pick a color theme</Heading>

			<Box px={4} my={3}>
				<Alert icon={false} severity='info'>
					<strong>Hello!</strong> Select your style below. Choose a theme that best fits
					your needs.
				</Alert>
			</Box>

			<Box px={4} my={3}>
				<Grid container spacing={3}>
					<Demo title='Dark' theme={THEMES.DARK} />
					<Demo title='Light' theme={THEMES.LIGHT} />
					<Demo title='Default' theme={THEMES.DEFAULT} />
					<Demo title='Blue' theme={THEMES.BLUE} />
					<Demo title='Green' theme={THEMES.GREEN} />
					<Demo title='Indigo' theme={THEMES.INDIGO} />
				</Grid>
			</Box>
		</Wrapper>
	);
}

function Settings({ show, closed }) {
	return (
		<React.Fragment>
			<Drawer anchor='right' open={show} onClose={closed}>
				<Demos />
			</Drawer>
		</React.Fragment>
	);
}

export default Settings;
