import { Auth } from 'aws-amplify';

export const getUserDetails = async () => {
	try {
		const signedInUser = await Auth.currentAuthenticatedUser();
		console.log('getting user info');

		let user = signedInUser;
		//Data return depends on current user state
		if (signedInUser.data) {
			user = signedInUser.data;
		}

		return {
			username: user.username,
			clientId: user.attributes['custom:companyid'] || '',
			email: user.attributes.email || '',
			phone: user.attributes.phone_number || '',
			group: user.signInUserSession.idToken.payload['cognito:groups'] || '',
			idToken: user.signInUserSession.idToken.jwtToken || '',
		};
	} catch (err) {
		return err;
	}
};
