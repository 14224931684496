/* eslint-disable prettier/prettier */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import config from './../../aws-exports.js';

import { Button, Paper, TextField as MuiTextField, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { Alert as MuiAlert } from '@mui/material';
import { ReactComponent as Logo } from '../../vendor/LTK Logo Dark Vector.svg';
import { ReactComponent as LennarLogo } from '../../vendor/Lennar_Mortgage.svg';

import { setSSOIdentity } from '../../redux/settings/settingsSlice.js';

const { REACT_APP_LTK_API_ENDPOINT } = process.env;
const { REACT_APP_LTK_API_BASE_URL } = process.env;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const BrandIcon = styled(Logo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const LennarBrandIcon = styled(LennarLogo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;


function SignInSSO() {
	const dispatch = useDispatch();
	const baseUrl = window.location.origin;

	return (
		<React.Fragment>
			<Helmet title='SSO Sign In' />
			<Paper sx={{ p: { xs: 6, md: 10 } }}>
				<LennarBrandIcon />
				<Typography component='h1' variant='h4' align='center' gutterBottom>
					The digital mortgage is just a click away!
				</Typography>
				<Typography component='h2' variant='body1' align='center'>
					Enter your company ID below to proceed to SSO login
				</Typography>
				<Formik
					initialValues={{
						companyId: '',
						submit: false,
					}}
					validationSchema={Yup.object().shape({
						companyId: Yup.string()
							.min(3)
							.max(255)
							.required('Company ID is required for SSO sign in'),
					})}
					onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
						try {
							// API call here to get redirect URL
							// If the companyId is invalid, API will instead return 400 with an error message
							let providerCheck = await fetch(
								`${REACT_APP_LTK_API_BASE_URL}/saml?accountId=${values.companyId}`,
								{
									method: 'POST',
									headers: {
										'x-localhost-overwrite':
											process.env.NODE_ENV === 'production' ? false : true,
									},
								}
							);
							let providerCheckJson = await providerCheck.json();

							if (providerCheckJson.redirectUrl && providerCheckJson.statusCode === 302) {
								dispatch(setSSOIdentity(values.companyId));
								window.location.assign(providerCheckJson.redirectUrl);
								return;
							} else {
								if ((providerCheckJson.message = 'Invalid Account ID')) {
									throw new Error('You entered an invalid Company ID. Please try again.');
								} else {
									throw new Error(providerCheckJson.message || providerCheck);
								}
							}
						} catch (error) {
							const message = error.message || 'Something went wrong';

							setStatus({ success: false });
							setErrors({ submit: message });
							setSubmitting(false);
						}
					}}>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
					}) => (
						<form noValidate onSubmit={handleSubmit}>
							{errors.submit && (
								<Alert mt={2} mb={1} severity='warning'>
									{errors.submit}
								</Alert>
							)}
							<TextField
								variant='standard'
								type='text'
								name='companyId'
								label='Company ID'
								value={values.companyId}
								error={Boolean(touched.companyId && errors.companyId)}
								fullWidth
								onBlur={handleBlur}
								onChange={handleChange}
								my={4}
							/>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								disabled={isSubmitting}>
								Submit
							</Button>
						</form>
					)}
				</Formik>
			</Paper>
			<Button
				component={RouterLink}
				to='/auth/sign-in'
				sx={{ fontSize: 14, textDecoration: 'underline', m: 2 }}
				variant='text'>
				Non-SSO Sign In
			</Button>
		</React.Fragment>
	);
}

export default SignInSSO;
