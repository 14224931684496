/* eslint-disable no-undef */
import { apiWrapper } from '../apiWrapper';
import { EncompassApi } from '../../EncompassApi/EncompassApi';

export async function me(token) {
	return await apiWrapper(getMe, [...arguments]);
}

export const getMe = async (token) => {
	return new Promise(async (resolve, reject) => {
		let api = new EncompassApi(token);
		try {
			let response = await api.getMe();
			resolve(response)
		} catch (ex) {
			reject(ex);
		}
	});
};
