import React, { useEffect, useState } from 'react';
import BaseDashboardLayout from './BaseDashboardLayout';
import { taskBasedWorkflowNavItems, taskBasedWorkflowNavItemsNonAdmin } from './../components/sidebar/items/taskBasedWorkflow';
import { useSelector } from 'react-redux';
import { getMe } from '../redux/encompass/encompassSlice';

const TaskBasedWorkflowDashboard = ({ children, width }) => {
	const encAdmin = useSelector(getMe);
	const [isAdmin, setIsAdmin] = useState(false);
	useEffect(() => { 
		console.log('encAdmin?', encAdmin);
		if (encAdmin.personas) {
			let personas = encAdmin.personas;
			for (let i = 0; i < personas.length; i++) {
				const persona = personas[i];
				if (persona.entityName === 'Super Administrator') {
					setIsAdmin(true);
				}
			}
		}
	},[encAdmin]);
	return (
		<React.Fragment>
			<BaseDashboardLayout
				children={children}
				width={width}
				navItems={isAdmin? taskBasedWorkflowNavItems: taskBasedWorkflowNavItemsNonAdmin}
				showMqttStatus={true}
			/>
		</React.Fragment>
	);
};

export default TaskBasedWorkflowDashboard;
