/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import jwt from 'jsonwebtoken';
import { StorageHelper } from '@aws-amplify/core';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import config from './../../aws-exports.js';

import { Paper, Typography, Button, LinearProgress } from '@mui/material';

import { CustomAlert as Alert } from '../../components/Alert';

const storage = new StorageHelper().getStorage();

const calculateClockDrift = (iatAccessToken, iatIdToken) => {
	const now = Math.floor(new Date() / 1000);
	const iat = Math.min(iatAccessToken, iatIdToken);
	return now - iat;
};

function SSOLanding() {
	const search = window.location.search;
	const baseUrl = window.location.origin;
	const params = new URLSearchParams(search);
	const accessCode = params.get('code');
	const [error, setError] = useState(params.get('error'));
	const [errorDescription, setErrorDescription] = useState(
		params.get('error_description')
	);
	const [showBackBtn, setShowBackBtn] = useState(false);
	const [bgColor, setbgColor] = useState('background.default');

	useEffect(() => {
		if (error) {
			setShowBackBtn(true);
		}

		// fallback in case anything goes wrong with sign-in
		setTimeout(() => {
			setShowBackBtn(true);
		}, 10000);
	}, []);

	useEffect(() => {
		handleSignIn();
	}, []);

	const handleSignIn = async () => {
		if (accessCode) {
			let clientId = config.aws_user_pools_web_client_id;
			try {
				const details = {
					grant_type: 'authorization_code',
					code: accessCode,
					client_id: clientId,
					redirect_uri: `${baseUrl}/auth/sso-landing`,
				};

				const formBody = Object.keys(details)
					.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
					.join('&');

				let data = await fetch('https://' + config.oauth.domain + '/oauth2/token', {
					/* eslint-enable no-undef */
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
					},
					body: formBody,
				}).then((res) => res.json());

				const idTokenData = jwt.decode(data['id_token']);
				const accessTokenData = jwt.decode(data['access_token']);

				storage.setItem(
					`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`,
					idTokenData['cognito:username']
				);
				storage.setItem(
					`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.idToken`,
					data['id_token']
				);
				storage.setItem(
					`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.accessToken`,
					data['access_token']
				);
				storage.setItem(
					`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.refreshToken`,
					data['refresh_token']
				);
				storage.setItem(
					`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.clockDrift`,
					'' + calculateClockDrift(accessTokenData['iat'], idTokenData['iat']) + ''
				);
				storage.setItem('amplify-signin-with-hostedUI', false);

				// Items need to be committed to local storage so a delay is needed before Auth.currentAuthenticatedUser() is called
				setTimeout(() => {
					checkUser();
				}, [2000]);

				async function checkUser() {
					try {
						let currentAuthUser = await Auth.currentAuthenticatedUser();
						if (!currentAuthUser) {
							handleSigninError({ Message: 'User not found or recognized' });
							return;
						}

						await Auth.userAttributes(currentAuthUser).then((currentUserInfo) => {
							if (!currentUserInfo) {
								handleSigninError({ Message: 'User not found or recognized' });
							} else {
								let UserAttributes = { UserAttributes: currentUserInfo };
								UserAttributes.Username = idTokenData['cognito:username'];

								storage.setItem(
									`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.userData`,
									JSON.stringify(UserAttributes)
								);

								// Items need to be committed to local storage so a delay is needed
								setTimeout(() => {
									// Hub event will be picked up by app.js
									Hub.dispatch('auth', { event: 'signIn' });
								}, 2000);
							}
						});
					} catch (err) {
						handleSigninError(err);
					}
				}
			} catch (err) {
				handleSigninError(err);
			}
		}
	};

	const handleSigninError = (err) => {
		console.error(err);
		setbgColor('background.paper');
		setError('Sign In Error');
		setErrorDescription(
			err.Message || 'An error occurred trying to sign in. Please try again.'
		);
		setShowBackBtn(true);
	};

	return (
		<React.Fragment>
			<Helmet title='SSO Sign In' />
			<Paper
				sx={{
					p: { xs: 6, md: 10 },
					bgcolor: bgColor,
				}}>
				{errorDescription ? (
					<React.Fragment>
						<Typography component='h1' variant='h4' align='center' color='error' mb={6}>
							An error occurred while signing you in.
						</Typography>
						<Alert severity='warning' title={error} description={errorDescription} />
					</React.Fragment>
				) : (
					<React.Fragment>
						<Typography component='h1' variant='h4' align='center' mb={6}>
							Please wait while we sign you in...
						</Typography>
						<LinearProgress />
					</React.Fragment>
				)}
			</Paper>
			{showBackBtn && (
				<Button
					component={RouterLink}
					to='/auth/sso'
					sx={{ fontSize: 14, textDecoration: 'underline', m: 2 }}
					variant='text'>
					Back to SSO Sign In Page
				</Button>
			)}
		</React.Fragment>
	);
}

export default SSOLanding;
