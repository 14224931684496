import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getConnectionType } from './../../redux/websocket/websocketSlice';

// Redirect is used just to redirect from / to other routes since having a / route caused issues with the sidebar
function Redirect({ children }) {
	const auth = useSelector((state) => state.auth.user);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const wsTopic = params.get('t');
	const wsEncryptionKey = encodeURIComponent(params.get('e'));
	const wsEncryptionIv = encodeURIComponent(params.get('i'));
	const wsEndpoint = params.get('url');
	const stateLoanGuid = useSelector((state) => state.loanPipeline.selectedGuid);
	const connectionType = useSelector(getConnectionType);
	const stateWsTopic = useSelector((state) => state.websocket.topic);
	const stateWsEncryptionKey = useSelector((state) => state.websocket.encryptionKey);
	const stateWsEncryptionIv = useSelector((state) => state.websocket.encryptionIv);
	const stateWsEndpoint = useSelector((state) => state.websocket.endpoint);

	if (!auth.username) {
		return <Navigate to='/auth/sign-in' />;
	} else {
		if (
			stateLoanGuid || connectionType === 'MQTT' || connectionType === 'SSF' ||
			(wsTopic && wsEncryptionKey && wsEncryptionIv && wsEndpoint) ||
			(stateWsTopic && stateWsEncryptionKey && stateWsEncryptionIv && stateWsEndpoint)
		) {
			return <Navigate to='/tbw/pipeline' />;
		}
	}

	return <Navigate to='/dashboard' />;
}

export default Redirect;
