import React from 'react';
import BaseDashboardLayout from './BaseDashboardLayout';
import { powerToolsItems } from '../components/sidebar/items/powerToolsItems';

const PowerToolsLayout = ({ children, width }) => {
	return (
		<BaseDashboardLayout children={children} width={width} navItems={powerToolsItems} />
	);
};

export default PowerToolsLayout;
