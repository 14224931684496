import React from 'react';
import { styled } from '@mui/material/styles';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Base = styled(SyntaxHighlighter)(({ theme }) => ({
	borderRadius: '3px',
	padding: `${theme.spacing(3)} !important`,
	backgroundColor: '#1b2430 !important',
}));

const Code = ({ children }) => {
	return (
		<Base language='javascript' style={vs2015}>
			{children}
		</Base>
	);
};

export default Code;
