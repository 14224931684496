import React from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(6),
	textAlign: 'center',
	background: 'transparent',
	[theme.theme.breakpoints.up('md')]: {
		padding: theme.spacing(10),
	},
}));

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		//logErrorToMyService(error, errorInfo); //Example
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage />;
		}

		return this.props.children;
	}
}

function ErrorPage() {
	return (
		<Wrapper>
			<Helmet title='Error' />
			<Typography component='h1' variant='h1' align='center' gutterBottom>
				Oh no, something appears to have gone wrong.
			</Typography>
			<Typography component='h2' variant='h5' align='center' gutterBottom>
				We apologize for the inconvenience.
			</Typography>
			<Typography component='h2' variant='body1' align='center' gutterBottom>
				Please try reloading the page and if the problem persists, please let us know.
			</Typography>
		</Wrapper>
	);
}

export default ErrorBoundary;
