import { createSlice } from '@reduxjs/toolkit';

export const loanPipelineSlice = createSlice({
	name: 'loanPipeline',
	initialState: {
		folders: [], //All folders the user has access to
		views: [], //All the pipeline views the user has setup
		view: {
			//The currently selected view that will be displayed in the UI
			id: '',
			name: '',
			viewType: '',
			loanOwnership: '',
		},
		selectedGuid: '', //Used to track which loan the user is drilling in to
		viewType: 'tasks', // View options [tasks, pipeline]
		taskViewType: 'myLoans', // The different types of task views [myLoans, allLoans]
		dispositions: null, // Task dispositions types, these are set by the company so they are dynamic
		loanView: 'myTasks', //Loan.JS caching for radio options [myTasks, allTasks]
		userGroups: [], // The companies user groups that they've setup
	},
	reducers: {
		setStateFolders: (state, action) => {
			state.folders = action.payload;
		},
		setStateViews: (state, action) => {
			state.views = action.payload;
		},
		setStateView: (state, action) => {
			state.view.id = action.payload.id;
			state.view.name = action.payload.name;
			state.view.viewType = action.payload.viewType;
			state.view.loanOwnership = action.payload.loanOwnership;
		},
		setSelectedGuid: (state, action) => {
			state.selectedGuid = action.payload;
		},
		setViewType: (state, action) => {
			state.viewType = action.payload;
		},
		setTaskViewType: (state, action) => {
			state.taskViewType = action.payload;
		},
		setDispositions: (state, action) => {
			state.dispositions = action.payload;
		},
		setLoanView: (state, action) => {
			state.loanView = action.payload;
		},
		setUserGroups: (state, action) => {
			state.userGroups = action.payload;
		},
	},
});

export const {
	setStateFolders,
	setStateViews,
	setStateView,
	setSelectedGuid,
	setViewType,
	setTaskViewType,
	setDispositions,
	setLoanView,
	setUserGroups,
} = loanPipelineSlice.actions;

export const getStateFolders = (state) => state.loanPipeline.folders;
export const getStateViews = (state) => state.loanPipeline.views;
export const getStateView = (state) => state.loanPipeline.view;
export const getViewId = (state) => state.loanPipeline.view.id;
export const getViewName = (state) => state.loanPipeline.view.name;
export const getPipelineViewType = (state) => state.loanPipeline.view.viewType;
export const getSelectedGuid = (state) => state.loanPipeline.selectedGuid;
export const getViewType = (state) => state.loanPipeline.viewType;
export const getTaskViewType = (state) => state.loanPipeline.taskViewType;
export const getDispositions = (state) => state.loanPipeline.dispositions;
export const getLoanView = (state) => state.loanPipeline.loanView;
export const getUserGroups = (state) => state.loanPipeline.userGroups;

export default loanPipelineSlice.reducer;
