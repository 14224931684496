import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import themeReducer from './theme/themeSlice';
import authReducer from './auth/authSlice';
import encompassReducer from './encompass/encompassSlice';
import loanPipelineReducer from './loanPipeline/loanPipelineSlice';
import websocketReducer from './websocket/websocketSlice';
import settingsReducer from './settings/settingsSlice';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: ['themeReducer', 'websocketReducer', 'settingsReducer'], //Things u want to persist
	blacklist: ['authReducer', 'loanPipelineReducer', 'encompassReducer'], //Things u dont
	stateReconciler: autoMergeLevel2,
};

const themePersistConfig = {
	key: 'theme',
	storage,
};

const websocketPersistConfig = {
	key: 'websocket',
	storage,
};

const settingsPersistConfig = {
	key: 'settings',
	storage,
};

const rootReducer = combineReducers({
	theme: persistReducer(themePersistConfig, themeReducer),
	auth: authReducer,
	encompass: encompassReducer,
	loanPipeline: loanPipelineReducer,
	websocket: persistReducer(websocketPersistConfig, websocketReducer),
	settings: persistReducer(settingsPersistConfig, settingsReducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});
