import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { getUser, userLogout } from './../../redux/auth/authSlice';
import { getConnectionType } from './../../redux/websocket/websocketSlice';

import { styled } from '@mui/material/styles';

import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton } from '@mui/material';

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

function NavbarUserDropdown({ toggleTheme }) {
	const user = useSelector(getUser);
	const connectionType = useSelector(getConnectionType);
	const [anchorMenu, setAnchorMenu] = React.useState(null);
	const dispatch = useDispatch();

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	const handleSignOut = async () => {
		try {
			await Auth.signOut();
			dispatch(userLogout());
		} catch (error) {
			console.log('error signing user out');
			console.log(error);
		}
	};

	const handleTheme = async () => {
		try {
			setAnchorMenu(null);
			toggleTheme();
		} catch (error) {
			console.error(error);
		}
	};

	if (user) {
		return (
			<React.Fragment>
				<Tooltip title='Account'>
					<IconButton
						aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
						aria-haspopup='true'
						onClick={toggleMenu}
						color='inherit'
						size='large'>
						<HomeIcon style={{ marginRight: 30, marginTop: 20, color: '#4a4a4a' }} />
					</IconButton>
				</Tooltip>
				<Menu
					id='menu-appbar'
					anchorEl={anchorMenu}
					open={Boolean(anchorMenu)}
					onClose={closeMenu}>
					<MenuItem onClick={handleTheme}>Choose Theme</MenuItem>
					<MenuItem component={Link} to='/profile/my-account'>
						My Profile
					</MenuItem>

					{(user.group.includes('company-admin') ||
						user.group.includes('ltk-user') ||
						user.group.includes('ltk-admin')) && (
						<MenuItem component={Link} to='/admin/my-company'>
							My Company
						</MenuItem>
					)}
					<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
				</Menu>
			</React.Fragment>
		);
	} else {
		return <React.Fragment />;
	}
}

export default NavbarUserDropdown;
