import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setRequestedPath } from './../../redux/auth/authSlice';

// For routes that can only be accessed by authenticated users
function CompanyAdmin({ children }) {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth.user);
	const path = window.location.pathname;

	if (!auth.username) {
		if (!path.includes('auth')) {
			dispatch(setRequestedPath(path));
		}

		return <Navigate to='/auth/sign-in' />;
	}

	if (
		auth.group.includes('company-admin') ||
		auth.group.includes('ltk-user') ||
		auth.group.includes('ltk-admin')
	) {
		return children;
	}

	return <Navigate to='/dashboard' />;
}

export default CompanyAdmin;
