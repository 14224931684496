import React from 'react';
import { styled } from '@mui/material/styles';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import SidebarNavSection from './SidebarNavSection';

import '../../vendor/perfect-scrollbar.css';

const Scrollbar = styled('div')`
	background-color: ${(props) => props.theme.sidebar.background};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
	background-color: ${(props) => props.theme.sidebar.background};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const Items = styled('div')`
	padding-top: ${(props) => props.theme.spacing(2.5)};
	padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav = ({ items }) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

	return (
		<ScrollbarComponent>
			<List disablePadding>
				<Items>
					{items &&
						items.map((item) => (
							<SidebarNavSection
								component='div'
								key={item.title}
								pages={item.pages}
								title={item.title}
							/>
						))}
				</Items>
			</List>
		</ScrollbarComponent>
	);
};

export default SidebarNav;
