import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setRequestedPath } from './../../redux/auth/authSlice';

// For routes that can only be accessed by authenticated users
function OptimizedLicenseGuard({ children }) {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth.user);
	const path = window.location.pathname;

	if (!auth.username) {
		if (!path.includes('auth')) {
			dispatch(setRequestedPath(path));
		}

		return <Navigate to='/auth/sign-in' />;
	} else {
		// TODO
		// Implement check here on whether licensed for optimized products or not
		// if() { return children; }

		//TODO
		// Remove this once actual licensing is in place
		// For now, just restrict Lennar
		if (auth.clientId !== '7') {
			return children;
		}
	}

	return <Navigate to='/dashboard' />;
}

export default OptimizedLicenseGuard;
