import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setRequestedPath } from './../../redux/auth/authSlice';
import {
	updateTopic,
	setSsf,
	setEncryptionKey,
	setEncryptionIv,
	setWsEndpoint,
	setConnectionType,
} from './../../redux/websocket/websocketSlice';
import { setSelectedGuid } from './../../redux/loanPipeline/loanPipelineSlice';
import { setSSO, setSSOIdentity } from './../../redux/settings/settingsSlice';
import config from './../../aws-exports';

const { REACT_APP_LTK_API_ENDPOINT } = process.env;
const { REACT_APP_LTK_API_BASE_URL } = process.env;

// For routes that can only be accessed by unauthenticated users
function TaskBasedWorkflow({ children }) {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth.user);
	const baseUrl = window.location.origin;
	const search = window.location.search;
	const path = window.location.pathname;
	const params = new URLSearchParams(search);
	const wsTopic = params.get('t');
	const wsEncryptionKey = encodeURIComponent(params.get('e'));
	const wsEncryptionIv = encodeURIComponent(params.get('i'));
	const wsEndpoint = params.get('url');
	const loanGuid = params.get('lg');
	const sso = params.get('sso');
	const ssoIdentity = params.get('ssoi');
	const secureScriptingFramework = params.get('ssf');

	if (!auth.username) {
		if (secureScriptingFramework === 'true') {
			console.log('Using Secure Scripting Framework...');

			// Set SSF Query Params
			dispatch(setSsf(true));
			dispatch(setConnectionType('SSF'));

			// Reset MQTT Query Params to null
			dispatch(updateTopic(null));
			dispatch(setEncryptionKey(null));
			dispatch(setEncryptionIv(null));
			dispatch(setWsEndpoint(null));

		} else if (wsTopic && wsEncryptionKey && wsEncryptionIv && wsEndpoint){
			console.log('Using Websocket...');
			
			// Set MQTT Query Params
			dispatch(updateTopic(wsTopic));
			dispatch(setEncryptionKey(wsEncryptionKey));
			dispatch(setEncryptionIv(wsEncryptionIv));
			dispatch(setWsEndpoint(wsEndpoint));
			dispatch(setConnectionType('MQTT'));

			// Reset SSF Query Params to null
			dispatch(setSsf(false));
		}
		
		if (loanGuid) {
			dispatch(setSelectedGuid(loanGuid));
		}
		if (!path.includes('auth')) {
			dispatch(setRequestedPath(path));
		}

		if (sso && ssoIdentity) {
			dispatch(setSSO(sso));
			dispatch(setSSOIdentity(ssoIdentity));

			try {
				// API call here to get redirect URL
				// If the companyId is invalid, API will instead return 400 with an error message
				fetch(`${REACT_APP_LTK_API_BASE_URL}/saml?accountId=${ssoIdentity}`, {
					method: 'POST',
					headers: {
						'x-localhost-overwrite': process.env.NODE_ENV === 'production' ? false : true,
					},
				})
					.then((response) => response.json())
					.then((providerCheckJson) => {
						if (providerCheckJson.redirectUrl && providerCheckJson.statusCode === 302) {
							dispatch(setSSOIdentity(ssoIdentity));
							window.location.assign(providerCheckJson.redirectUrl);
							return;
						} else {
							return <Navigate to='/auth/sso' />;
						}
					});
			} catch (err) {
				console.error(err);
			}

			return <Navigate to='/auth/sso' />;
		}

		return <Navigate to='/auth/sign-in' />;
	} else {
		// TODO
		// Implement check here on whether licensed for TBW or not
		// if() { return children; }

		return children;
	}
}

export default TaskBasedWorkflow;
