import Amplify, { Auth, PubSub } from 'aws-amplify';
import { MqttOverWSProvider } from '@aws-amplify/pubsub/lib/Providers';

export const requestToken = (topic, endpoint) => {
	console.log('requesting new token');
	return new Promise(async (resolve, reject) => {
		try {
			let cognitoUser = await Auth.currentAuthenticatedUser();
			let jwtToken = cognitoUser.signInUserSession.idToken.jwtToken || '';

			Amplify.addPluggable(
				new MqttOverWSProvider({
					//here you would include your token as the query string parameter use to initialize the connection
					aws_pubsub_endpoint: `wss://${endpoint}/mqtt?token=${jwtToken}`,
				})
			);

			Auth.currentSession().then(() => {
				PubSub.publish(topic, { type: 'TokenRequest' });
				resolve();
			});
		} catch (err) {
			reject(err);
		}
	});
};
