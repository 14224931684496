import { createMachine, assign, actions, send } from 'xstate';
import { mqttMachine } from './mqttMachine';
import { ssfMachine } from './ssfMachine';

const { raise, log, choose } = actions;

const tokenRequestRequiresActiveConnection = choose([
	{
		//Do nothing. The token request should only occur if the connection is actually an MQTT connection type
		cond: (context, event) => {
			if (context.connectionType !== 'mqtt') {
				return true;
			} else {
				return false;
			}
		},
		actions: {}, //do nothing
	},
	{
		//If there is an active MQTT connection, forward to mqttMachine
		cond: (context, event) => {
			console.log('connected', context.connected);
			if (context.connected) {
				return true;
			} else {
				return false;
			}
		},
		actions: send({ type: 'TOKENREQUEST' }, { to: 'mqttMachine' }),
	},
	{
		//If the connection type is MQTT and there is no active MQTT connection, spawn one
		cond: (context, event) => true,
		actions: [
			log(
				'A token is being requested but no active MQTT connections exist. Spawing new MQTT connection now.'
			),
			send({ type: 'RESETMQTT' }),
		],
	},
]);

export const appMachine = createMachine(
	{
		id: 'app',
		initial: 'nullConnectionType',
		context: {
			connectionType: null, //Can either be api or mqtt
			token: '',
			company: '',
			retries: 0,
			connectionAttempts: 0,
			disconnectReason: '',
			error: false,
			errorMsg: null,
			connected: false,
			pipelineMachine: null,
			mqttTopic: '',
			mqttEncryptionKey: '',
			mqttEncryptionIv: '',
			mqttEndpoint: '',
			viz: false, //to use a vizualizer like https://xstate.js.org/viz/?gist=9f37af5b7728fd1a06fbea27b11d4227 change this to true
		},
		on: {
			RESETMQTT: {
				target: 'mqtt',
			},
			RESETSSF: {
				target: 'ssf',
			},
			TOKENREQUEST: {
				actions: [
					log('A token is being requested.'),
					tokenRequestRequiresActiveConnection,
				],
			},
		},
		states: {
			nullConnectionType: {
				entry: () => {
					console.log('appMachine initialized');
				},
				on: {
					API: {
						target: 'api', //For API Credential Connections
						//This is not currently in use, just available as a fallback for the future
						//that could be used in cases where there is a failure and a more static
						//direct API connection is needed
					},
					MQTT: {
						target: 'mqtt', //For MQTT Connections
						actions: [
							log('MQTT connection initiated'),
							assign({ connectionType: 'mqtt' }),
							assign({ mqttTopic: (context, event) => event.topic }),
							assign({ mqttEncryptionKey: (context, event) => event.key }),
							assign({ mqttEncryptionIv: (context, event) => event.iv }),
							assign({ mqttEndpoint: (context, event) => event.endpoint }),
							assign({ token: (context, event) => event.token }),
						],
					},
					SSF: {
						target: 'ssf', //For SSF Connections
						actions: [
							log('SSF Connection Initiated'),
							assign({ connectionType: 'ssf' }),
							assign({ company: (context, event) => event.company }),
							assign({ token: (context, event) => event.token }),
						],
					},
				},
			},
			mqtt: {
				entry: [
					() => {
						console.log('appMachine state is mqttToken');
					},
				],
				invoke: {
					//Invoke MQTT connection on child mqttMachine
					id: 'mqttMachine',
					src: mqttMachine,
					data: {
						token: (context, event) => context.token,
						mqttTopic: (context, event) => context.mqttTopic,
						mqttEncryptionKey: (context, event) => context.mqttEncryptionKey,
						mqttEncryptionIv: (context, event) => context.mqttEncryptionIv,
						mqttEndpoint: (context, event) => context.mqttEndpoint,
					},
					onDone: {
						target: 'mqttDisconnect',
						actions: assign({
							disconnectReason: (context, event) => {
								//TODO
								//handle disconnect reason assignment
								console.log('mqttDisconnect due to ', event.data);
								return event.data.type;
							},
							//TODO if needed
							//If the disconnection is due to an error, event.data will also include event.data.errorMessage if one has been defined
						}),
					},
				},
				on: {
					CONNECTED: {
						actions: [
							log('appMachine received CONNECTED event'),
							assign({ connected: true }),
						],
					},
					TOKENRECEIVED: {
						actions: assign({ token: (context, event) => event.payload }),
					},
					MQTTMESSAGE: {
						actions: log(
							(context, event) =>
								`MQTT Message received by appMachine, msg: ${event.data}`
						),
					},
				},
			},
			ssf: {
				entry: [
					() => {
						console.log('appMachine state is ssfToken');
					},
				],
				invoke: {
					// Invoke SSF connection on child ssfMachine
					id: 'ssfMachine',
					src: ssfMachine,
					data: {
						company: (context, event) => context.company,
						token: (context, event) => context.token,
					},
					onDone: {
						target: 'ssfDisconnect',
						actions: assign({
							disconnectReason: (context, event) => {
								console.log('ssfDisconnect due to ', event.data);
								return event.data.type;
							},
						}),
					},
				},
				on: {
					CONNECTED: {
						actions: [
							log('appMachine received CONNECTED ssf event'),
							assign({ connected: true }),
						],
					},
					TOKENRECEIVED: {
						actions: [
							log('appMachine received TOKENRECEIVED ssf event'),
							assign({
								token: (context, event) => event.payload,
							}),
						],
					},
					SSFMESSAGE: {
						actions: log(
							(context, event) => `SSF Message received by appMachine, msg: ${event.data}`
						),
					},
				},
			},
			mqttDisconnect: {
				entry: [
					() => {
						console.log('appMachine state is mqttDisconnect');
					},
					assign({ connected: false }),
				],
				always: 'connectionFailure',
			},
			ssfDisconnect: {
				entry: [
					() => {
						console.log('appMachine state is ssfDisconnect');
					},
					assign({ connected: false }),
				],
				always: 'connectionFailure',
			},
			connectionFailure: {
				entry: 'incrementRetry',
				after: {
					RETRY_DELAY: [
						//send to connecting again for mqtt or to api for api connections
						//If the operation has already been retried 10 times though, will route to error
						{
							target: 'mqtt',
							cond: (context) =>
								context.connectionType === 'mqtt' && context.retries < 11,
						},
						{ target: 'api', cond: 'maxRetries' },
						{ target: 'error' },
					],
				},
			},
			api: {
				entry: [
					assign({ connectionType: 'api' }),
					'resetRetry',
					() => {
						console.log('appMachine state set to API connection');
					},
				],
				//entry: {}, //Do some work and grab a token
				on: {
					TOKEN: {
						//action: //grab and store token then pass to connected state
					},
					ERROR: {
						target: 'connectionFailure',
					},
				},
			},
			error: {
				entry: () => [console.log('appMachine entered error state'), 'resetRetry'],
				on: {
					RETRY: [{ target: 'mqtt', cond: 'isMQTT' }, { target: 'api' }],
				},
			},
		},
	},
	{
		guards: {
			isSSF: (context) => context.connectionType === 'ssf',
			isMQTT: (context) => context.connectionType === 'mqtt',
			tokenCheck: (context) => context.token !== '',
			maxRetries: (context) => context.retries < 11,
			maxConnectionAttempts: (context) => context.connectionAttempts < 11,
		},
		delays: {
			RETRY_DELAY: (context, event) => {
				return context.retries * 500; //incremental backoff
			},
		},
		actions: {
			setToken: assign({
				token: (context, event) => {
					return event.data ? event.data : context.token;
				},
			}),
			clearToken: assign({ token: '' }),
			incrementRetry: assign({ retries: (context) => context.retries + 1 }),
			resetRetry: assign({ retries: 0 }),
			resetConnectionAttempts: assign({ connectionAttempts: 0 }),
			incrementConnectionAttempt: assign({
				retries: (context) => context.connectionAttempts + 1,
			}),
			raiseCheckToken: raise('CHECKTOKEN'),
		},
	}
);
