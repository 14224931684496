import React, { useEffect, useState, Suspense } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Hub, Logger } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
//import { raygunInit } from './utils/raygun';

import { appMachine } from './services/stateMachines/appMachine';
import { useMachine } from '@xstate/react';

import routes from './routes';

import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';

import { getTheme } from './redux/theme/themeSlice';
import createTheme from './theme';
import { ThemeProvider } from '@mui/material/styles';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
	userLogin,
	userLogout,
	getCompany,
	getEncompassToken,
	setEncompassToken,
	setRequestedPath,
	setCompany,
} from './redux/auth/authSlice';
import {
	setStandardFields,
	setUsers,
	setMe,
	setUserCdo,
	setUserCdoLoanView,
	setLastUserUpdate,
	setLimiterEnabled,
	setLimiterField,
	setLimiterValue,
	setPort,
} from './redux/encompass/encompassSlice';
import {
	setUserGroups,
	getUserGroups as getUserGroupsState,
} from './redux/loanPipeline/loanPipelineSlice';
import {
	setConnectionType,
	updateTokenStatus,
	getTopic,
	getSsf,
	getEncryptionKey,
	getEncryptionIv,
	getWsEndpoint,
	getTokenStatus,
	updateConnectionStatus,
} from './redux/websocket/websocketSlice';
import { getUserGroups } from './services/encompass/settings/getUserGroups';
//todo create this method
import { listUsers } from './services/encompass/userSettings/listUsers';
import { getEncompassStandardFields } from './services/storage/getEncompassStandardFields';
import { getUserDetails } from './utils/getUserDetails';
import { getCompanyInfo } from './utils/getCompanyInfo';
import { me } from './services/encompass/userSettings/me';
import { getUserCdo } from './services/encompass/userSettings/userCdos';
import { EncompassApi } from './services/EncompassApi/EncompassApi';

//Provdes a lot of logging out of the box. Might be useful on dev and prod or perhaps just dev
const logger = new Logger('My-Logger');

function App() {
	const content = useRoutes(routes);
	const dispatch = useDispatch();
	const [user, setUser] = useState(null);
	let topic = useSelector(getTopic);
	let ssf = useSelector(getSsf);
	let key = useSelector(getEncryptionKey);
	let iv = useSelector(getEncryptionIv);
	let endpoint = useSelector(getWsEndpoint);
	const token = useSelector(getEncompassToken);
	let company = useSelector(getCompany);
	const searchUrl = useLocation().search;
	const portParameter = new URLSearchParams(searchUrl).get('port');

	const [state, send] = useMachine(appMachine, {
		context: {},
		devTools: process.env.NODE_ENV !== 'production',
	});

	let tokenStatus = useSelector(getTokenStatus);
	let userGroups = useSelector(getUserGroupsState);

	useEffect(() => {
		console.log('App: useEffect() calling mqttConnection()');
		mqttConnection();
	}, [mqttConnection]);

	useEffect(() => {
		checkCognitoUser();
		//Hub will notify of auth events, can easily subscribe and handle
		authListener();
		console.log('port', portParameter);
		dispatch(setPort(portParameter));
	}, []);

	useEffect(() => {
		//the token status in redux is used primarily for the apiWrapper.js to wrap Encompass API calls
		//When a call fails because of an expired or bad token, redux is used to signify to the appMachine and appMachineProvider that a new
		//token is needed. That is what is happening here is we are requesting that new token
		if (tokenStatus === 'refreshing') {
			console.log('tokenStatus is refreshing');
			//requestToken(topic, endpoint);
			send({ type: 'TOKENREQUEST' });
			dispatch(updateTokenStatus('requested'));
		}
	}, [tokenStatus]);

	useEffect(() => {
		if (state.context.token && state.context.token !== '') {
			console.log('Updating Token Status to set...');
			dispatch(updateTokenStatus('set'));
			console.log('Setting Encompass Token...');
			dispatch(setEncompassToken(state.context.token));
			if (userGroups.length === 0) {
				getSetUserGroups(state.context.token);
			}
			console.log('Fetching Encompass Users');
			getSetUsers(state.context.token);
			getSetFilters(state.context.token);
		}
	}, [state.context.token]);

	useEffect(() => {
		dispatch(updateConnectionStatus(state.context.connected));
	}, [state.context.connected]);

	async function getSetUserGroups(token) {
		//Retrieve and set user group info in Redux.
		//This info will be used for Task Based Workflow when the assignment is a user group since
		//the data shows just a number, rather than the name of the user group
		try {
			let userGroupInfo = await getUserGroups(token);
			dispatch(setUserGroups(JSON.stringify(userGroupInfo)));
		} catch (err) {
			console.error('Failed to retrieve user group info, error: ', err);
		}
	}

	async function getSetUsers(token) {
		const api = new EncompassApi(token);
		let settingsCdo = await api.getGlobalCdo('LTK-TBW-SETTINGS');
		console.log('settings?', settingsCdo);
		if (settingsCdo && !settingsCdo.errorCode) {
			let decodedCdo = atob(settingsCdo.dataObject);
			let object = JSON.parse(decodedCdo);
			console.log('settings Object =>', object);
			dispatch(setUsers(object.userList));
			dispatch(setLastUserUpdate(object.lastUpdated));
			dispatch(setLimiterEnabled(object.limiterEnabled));
			dispatch(setLimiterField(object.limiterField));
			dispatch(setLimiterValue(object.limiterValue));
		} else { 
			console.log('no object');
		}
		// try { 
		// 	let users = await listUsers(token);
		// 	dispatch(setUsers(users));
		// } catch (err) {
		// 	console.error('failed to retrieve encompass users, error: ', err);
		// }
	}

	async function getSetFilters(token) {
		try {
			let currentUser = await me(token);
			dispatch(setMe(currentUser));
			if (currentUser) {
				//TODO: try catch
				let userCdo = null;
				let userCdoLoanView = null;
				try {
					let response = await getUserCdo(currentUser.id, token, 'tbwGF');
					if (response.name !== 'Error') {
						console.log('response.name', response.name);
						userCdo = response;
					} else { 
						console.log('no user filters');
					}
				} catch { }
				try {
					let response = await getUserCdo(currentUser.id, token, 'tbwLF');
					if (response.name !== 'Error') {
						console.log('response.name', response.name);
						userCdoLoanView = response;
					}
				} catch { }
				if (userCdo) {
					let decodeCdo = atob(userCdo.dataObject);
					let object = JSON.parse(decodeCdo);
					dispatch(setUserCdo(object));
				}
				if (userCdoLoanView) {
					let decodeCdo = atob(userCdoLoanView.dataObject);
					let object = JSON.parse(decodeCdo);
					dispatch(setUserCdoLoanView(object));
				}
			}
		} catch (err) {
			console.error('failed to retrieve me', err);
		}
	}

	async function authListener() {
		console.log('ENTER authListener()');
		//Listen and handle Amplify auth events
		Hub.listen('auth', async (data) => {
			//data will include the Cognito JWT as well as all user payload
			switch (data.payload.event) {
				case 'signIn':
					console.log('signed in');
					logger.info('user signed in');
					//Update state and render hidden content
					let signedInUser = await getUserDetails();
					setUser(signedInUser);

					let userCompany = await getCompanyInfo();
					console.log('userCompany =>', userCompany);
					dispatch(setCompany(userCompany));

					//raygunInit(signedInUser);
					dispatch(userLogin(signedInUser));

					console.log('App: authListener() calling mqttConnection()');
					await mqttConnection();

					//Get standard Encompass fields and store in Redux
					//let encStandardFields = await getEncompassStandardFields();
					//console.log('encStan', encStandardFields);
					//dispatch(setStandardFields(encStandardFields));

					//get encompass users?
					let users = await listUsers(token);
					break;
				case 'signUp':
					logger.info('user signed up');
					break;
				case 'signOut':
					console.log('user signed out');
					logger.info('user signed out');
					dispatch(userLogout());
					dispatch(setRequestedPath(''));
					break;
				case 'signIn_failure':
					logger.error('user sign in failed');
					break;
				case 'tokenRefresh':
					logger.info('token refresh succeeded');
					break;
				case 'tokenRefresh_failure':
					logger.error('token refresh failed');
					logger.info('token refresh failed');
					dispatch(userLogout());
					break;
				default:
					console.log('not signed in');
					logger.error('none');
			}
		});
	}

	//Function to attempt to authorize user
	async function checkCognitoUser() {
		try {
			let signedInUser = await getUserDetails();
			if (signedInUser.username) {
				dispatch(userLogin(signedInUser));

				let userCompany = await getCompanyInfo();
				dispatch(setCompany(userCompany));

				setUser(signedInUser);
				//raygunInit(signedInUser);
				//Get standard Encompass fields and store in Redux
				let encStandardFields = await getEncompassStandardFields();
				dispatch(setStandardFields(encStandardFields));
			}
		} catch (err) {
			console.log('no user');
		}
	}

	// This will be called multiple times which is okay, ignore eslint error as a result
	// eslint-disable-next-line react-hooks/exhaustive-deps
	async function  mqttConnection() {
		//Establish connection here
		console.log('mqttConnection() topic =>', topic);
		console.log('mqttConnection() key =>', key);
		console.log('mqttConnection() iv =>', iv);
		console.log('mqttConnection() endpoint =>', endpoint);
		console.log('mqttConnection() user =>', user);
		console.log('mqttConnection() company =>', company);
		console.log('mqttConnection() token =>', token);

		if (topic && key && iv && endpoint && user) {
			console.log("App.js - mqttConnection() SetConnectionType to MQTT");
			dispatch(setConnectionType('MQTT'));
			console.log('App.js - mqttConnection() Send MQTT');
			send('MQTT', { topic, key, iv, endpoint, token });
		} else if (ssf && user) {
			console.log('App.js - mqttConnection() SetConnectionType to SSF');
			dispatch(setConnectionType('SSF'));
			console.log('App.js - mqttConnection() SEND SSF');
			send('SSF', { company, token });
		} else {
			console.log('App.js - mqttConnection() SetConnectionType to API');
			dispatch(setConnectionType('API'));
		}
	}

	// add action to all snackbars
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const theme = useSelector(getTheme);
	return (
		<HelmetProvider>
			<Helmet titleTemplate='%s' defaultTitle='LTK Sign Up' />
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<ThemeProvider theme={createTheme(theme)}>
					<SnackbarProvider
						ref={notistackRef}
						action={(key) => (
							<IconButton aria-label='dismiss' onClick={onClickDismiss(key)}>
								<CloseIcon />
							</IconButton>
						)}
						maxSnack={3}
						preventDuplicate
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}>
						<ErrorBoundary>
							<Suspense fallback={<Loader />}>{content}</Suspense>
						</ErrorBoundary>
					</SnackbarProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</HelmetProvider>
	);
}

export default App;
