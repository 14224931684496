import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

export const CustomAlert = ({
	severity,
	title,
	description,
	actionType,
	customAction,
	icon,
	variant,
	color,
	width,
}) => {
	//Review the material-ui docs for more information on the alert component

	let alertSeverity = severity || 'error'; //Default is error, options are error, warning, info, or success
	let alertTitle = title || null; //Default is no title, will only render if a title is passed
	let alertDescription = description; //*Required
	let alertActionType = actionType || 'none'; //Default will be to render no action. Other options are 'close' or 'custom'.
	let alertCustomAction = customAction; //Required when actionType = 'custom'
	//TODO make alertIcon function as intended
	let alertIcon = icon || true; //Pass in either false to show no icone or a custom icon otherwise the default for the severity value will be shown
	//TODO wire up alert variant and color
	//let alertVariant = variant || null; //Default is applied unless you specify either outlined or filled
	//let alertColor = color || null; //Default will render based on severity unless you override
	let alertWidth = width || '100%'; //Default will be 100% unless you override

	function NoActionAlert() {
		return (
			<Alert
				sx={{
					width: alertWidth,
					marginTop: 2,
				}}
				severity={alertSeverity}>
				{alertTitle !== null && <AlertTitle>{alertTitle}</AlertTitle>}
				{alertDescription}
			</Alert>
		);
	}

	function ActionAlert() {
		const [open, setOpen] = React.useState(true);
		return (
			<Collapse in={open}>
				<Alert
					sx={{
						width: alertWidth,
						marginTop: 2,
					}}
					severity={alertSeverity}
					icon={alertIcon}
					action={
						<IconButton
							aria-label='close'
							color='inherit'
							size='small'
							onClick={() => {
								setOpen(false);
							}}>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					}>
					{alertTitle !== null && <AlertTitle>{alertTitle}</AlertTitle>}
					{alertDescription}
				</Alert>
			</Collapse>
		);
	}

	function CustomActionAlert() {
		return (
			<Alert
				sx={{
					width: alertWidth,
					marginTop: 2,
				}}
				severity={alertSeverity}
				icon={alertIcon}
				action={alertCustomAction}>
				{alertTitle !== null && <AlertTitle>{alertTitle}</AlertTitle>}
				{alertDescription}
			</Alert>
		);
	}

	return (
		<React.Fragment>
			{alertActionType === 'none' && <NoActionAlert />}
			{alertActionType === 'close' && <ActionAlert />}
			{alertActionType === 'custom' && <CustomActionAlert />}
		</React.Fragment>
	);
};
