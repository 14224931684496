import React from 'react';

const retry = (fn, retriesLeft = 10, interval = 500) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}
					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

const lazyLoad = (fn) => React.lazy(() => retry(fn));
export default lazyLoad;
