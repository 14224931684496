/* eslint-disable no-undef */
import { apiWrapper } from '../apiWrapper';
//import * as EncompassApi from '@lendertoolkit/encompass_api_browser';
import { resolveConfigFile } from 'prettier';
import { EncompassApi } from '../../EncompassApi/EncompassApi';

export async function listUsers(token) {
	return await apiWrapper(getUsers, [...arguments]);
}

const filterUsers = async (userList) => {
	let filteredUsers = [];
	for (let i = 0; i < userList.length; i++) {
		const user = userList[i];
		let disabled = false;
		if (user.personas) {
			if (user.userIndicators) {
				user.userIndicators.forEach((indicator) => {
					if (indicator === 'Disabled') {
						disabled = true;
					}
				});
				if (disabled == false) {
					filteredUsers.push(user);
				}
			}
		}
	}
	return filteredUsers;
};

export const getUsers = async (token) => {
	return new Promise(async (resolve, reject) => {
		let api = new EncompassApi(token);
		try {
			let response = await api.getUserList();
			let filteredUsers = filterUsers(response);
			resolve(filteredUsers)
		} catch (ex) {
			reject(ex);
		}
	});
};
