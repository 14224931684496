import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
//import { updateUsername } from '../../redux/auth/authSlice';

import {
	Button,
	Paper,
	Card as MuiCard,
	CardContent,
	TextField as MuiTextField,
	Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { Alert as MuiAlert } from '@mui/material';
import { ReactComponent as Logo } from '../../vendor/LTK Logo Dark Vector.svg';
import { ReactComponent as LennarLogo } from '../../vendor/Lennar_Mortgage.svg';

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const BrandIcon = styled(Logo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const LennarBrandIcon = styled(LennarLogo)`
	color: ${(props) => props.theme.sidebar.header.brand.color};
	fill: ${(props) => props.theme.sidebar.header.brand.color};
	width: 372px;
	height: 120px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
	width: 50%;
`;

const Wrapper = styled(Paper)`
	padding: ${(props) => props.theme.spacing(6)};

	${(props) => props.theme.breakpoints.up('md')} {
		padding: ${(props) => props.theme.spacing(10)};
	}
`;

function ResetPassword() {
	const navigate = useNavigate();

	function routeToSignIn() {
		navigate('/auth/sign-in');
	}

	return (
		<React.Fragment>
			<Helmet title='Forgot Password' />
			<Wrapper>
				<LennarBrandIcon />
				<Typography component='h1' variant='h4' align='center' gutterBottom>
					Forgot Password
				</Typography>
				<Typography component='h2' variant='body1' align='center'>
					Enter your username.
				</Typography>

				<Formik
					initialValues={{
						username: '',
						submit: false,
					}}
					validationSchema={Yup.object().shape({
						username: Yup.string()
							.max(255)
							.required('Username is required')
							.min(1)
							.required('Username is required'),
					})}
					onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
						try {
							let username = `${values.username}`;
							console.log(username);
							//dispatch(updateUsername(username));
							Auth.forgotPassword(username).then((data) => {
								console.log('Password reset confirmation sent');
								navigate('/auth/confirm-forgot-password');
							});
						} catch (error) {
							const message = error.message || 'Something went wrong';
							console.log({ error });
							setStatus({ success: false });
							setErrors({ submit: message });
							setSubmitting(false);
						}
					}}>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values,
					}) => (
						<form noValidate onSubmit={handleSubmit}>
							{errors.submit && (
								<Alert mt={2} mb={1} severity='warning'>
									{errors.submit}
								</Alert>
							)}
							<TextField
								variant='standard'
								type='username'
								name='username'
								label='Username'
								value={values.username}
								error={Boolean(touched.username && errors.username)}
								fullWidth
								helperText='Ex. janedoe123'
								onBlur={handleBlur}
								onChange={handleChange}
								my={3}
							/>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								disabled={isSubmitting}>
								Reset password
							</Button>
							<Button onClick={routeToSignIn} fullWidth color='primary'>
								Take me back to sign in page
							</Button>
						</form>
					)}
				</Formik>
			</Wrapper>
		</React.Fragment>
	);
}

export default ResetPassword;
