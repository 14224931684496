import { Layout, Sliders, ArrowLeftCircle, FileText, Settings } from 'react-feather';

// If the user is hitting the portal for TBW purposes, will limit the routes
export const taskBasedWorkflowNavItems = [
	{
		title: '',
		pages: [
			{
				href: '/dashboard',
				icon: ArrowLeftCircle,
				title: 'Go Back',
			},
		],
	},
	{
		title: 'Task Management',
		pages: [
			{
				href: '/tbw/dashboard',
				title: 'Dashboard',
				icon: Sliders,
			},
			{
				href: '/tbw/pipeline',
				icon: Layout,
				title: 'Pipeline',
			},
			{
				href: '/tbw/loan',
				icon: FileText,
				title: 'Loan',
			},
			{
				href: '/tbw/settings',
				icon: Settings,
				title: 'Settings'
			},
		],
	},
];

export const taskBasedWorkflowNavItemsNonAdmin = [
	{
		title: '',
		pages: [
			{
				href: '/dashboard',
				icon: ArrowLeftCircle,
				title: 'Go Back',
			},
		],
	},
	{
		title: 'Task Management',
		pages: [
			{
				href: '/tbw/dashboard',
				title: 'Dashboard',
				icon: Sliders,
			},
			{
				href: '/tbw/pipeline',
				icon: Layout,
				title: 'Pipeline',
			},
			{
				href: '/tbw/loan',
				icon: FileText,
				title: 'Loan',
			},
		],
	},
];
