import { createMachine, actions, forwardTo } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { Auth } from 'aws-amplify';
import emssf from '@elliemae/em-ssf-guest';
import axios from 'axios';

const { log, assign } = actions;

async function getJwtToken() {
	return new Promise(async (resolve, reject) => {
		let cognitoUser = await Auth.currentAuthenticatedUser();
		let jwtToken = cognitoUser.signInUserSession.idToken.jwtToken || '';
		resolve(jwtToken);
	});
}

async function exchangeAuthCodeForToken(company) {
	// Get JWT Token for LTK API Call
	let token = await getJwtToken();

	// Get SSF Auth Code
	let authCode = await getAuthCode();
	console.log('Auth Code =>', authCode);

	// Return Company Info
	console.log('Company Info =>', company);

	// HERE WE HAVE LOGIC ON IF WE NEED TO USE LTK T.E. OR CLIENT T.E.
	var config;
	if (company.LTKTokenExchangeConfig) {
		// LTK Token Exchange
		config = {
			method: 'post',
			url: company.TokenExchangeUrl,
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			},
			data: JSON.stringify({
				configurationId: company.LTKTokenExchangeConfig,
				authCode: authCode,
			}),
		};
	} else {
		// Example: 'https://esplatform.eaglehm.com/tokenexchange-uat/token/[[AUTHCODE]]?code=[[AUTH]]'
		let lennarTokenExchangeUrl = company.TokenExchangeUrl;

		// Do any variable replacement we need
		lennarTokenExchangeUrl = lennarTokenExchangeUrl
			.replace('[[AUTHCODE]]', authCode)
			.replace('[[AUTH]]', company.TokenExchangeAuth);

		// Lennar Token Exchange
		config = {
			method: 'get',
			url: lennarTokenExchangeUrl,
			headers: {
				'Content-Type': 'application/json',
			},
		};
	}

	console.log('Token Exchange Config =>', config);
	let response = await axios(config);
	return response.data;
}

async function getAuthCode() {
	return new Promise(async (resolve, reject) => {
		// Connect
		await emssf.connect();

		// Get Auth Object
		var authObject = await emssf.getObject('auth');
		console.log('authObject', authObject);

		// Get Auth Code
		authObject
			.createAuthCode()
			.then((code) => {
				resolve(code);
			})
			.catch((error) => {
				console.log('Error: ' + error);
				reject(error);
			});
	});
}

export const ssfMachine = createMachine(
	{
		id: 'ssfMachine',
		initial: 'connect',
		context: {
			company: '',
			errorMessage: '',
			initialTokenRequested: false,
		},
		on: {
			TOKENREQUEST: {
				actions: forwardTo('ssfConnection'),
			},
			//To test killing the machine, comment out the above and use the below and then change one of the API calls so that the token
			//is wrong, e.g. in updateTask.js add on text to the token that is passed to the function so that the API call is rejected
			//since this kicks off the TOKENREQUEST
			//TOKENREQUEST: { target: 'error' },
		},
		states: {
			reconnect: {
				invoke: {
					id: 'reconnect',
					src: {},
					onDone: {
						target: 'connect',
					},
				},
			},
			connect: {
				invoke: {
					id: 'ssfConnection',
					src: (context, event) => async (callback) => {
						console.log(`ssfMachine Connect called...`);

						// Exchange Auth Code for Token
						console.log('Getting Encompass Token...');
						let token = await exchangeAuthCodeForToken(context.company);
						console.log('Encompass Token =>', token);

						callback({ type: 'TOKENRECEIVED', payload: token.access_token });
						callback('CONNECTED');

						return () => {
							// Do nothing at all;
						};
					},
					onError: {
						target: 'error',
					},
				},
				on: {
					CONNECTED: {
						actions: sendParent({ type: 'CONNECTED' }),
					},
					INITIALTOKENREQUESTED: {
						actions: assign({ initialTokenRequested: true }),
					},
					TOKENRECEIVED: {
						actions: sendParent((context, event) => ({
							...event,
							type: 'TOKENRECEIVED',
						})),
					},
					MSGRECEIVED: {
						actions: [
							log((context, event) => {
								if (event?.payload?.type !== 'TokenRequest') {
									return 'MQTT message received:';
								}
							}),
							log((context, event) => {
								if (event?.payload?.type !== 'TokenRequest') {
									return event.payload;
								}
							}),
						],
					},
					RESTART: {
						target: 'reconnect',
					},
					ERROR: {
						target: 'error',
						actions: assign({
							errorMessage: (context, event) => (event.payload ? event.payload : ''),
						}),
					},
					CLOSE: { target: 'close' },
				},
			},
			error: {
				type: 'final',
				data: {
					type: 'error', //send info to appMachine
					errorMessage: (context, event) => context.errorMessage,
				},
			},
			close: {
				type: 'final',
				data: {
					type: 'closed',
				},
			},
		},
	},
	{
		actions: {
			tokenRequest: (context, event) => {
				console.log('tokenRequest called');
				// requestToken(context.mqttTopic, context.mqttEndpoint);
			},
		},
	}
);
