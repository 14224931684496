import { formLabelClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';

export const encompassSlice = createSlice({
	name: 'encompass',
	initialState: {
		standardFields: [],
		users: [],
		me: {},
		userCdo: null,
		userCdoLoanView: null,
		lastUserUpdate: '',
		limiterEnabled: null,
		limiterField: '',
		limiterValue: '',
		port: '',
		loanViewTasks: [],
	},
	reducers: {
		setStandardFields: (state, action) => {
			state.standardFields = action.payload;
		},
		setUsers: (state, action) => { 
			state.users = action.payload;
		},
		setMe: (state, action) => {
			state.me = action.payload;
		},
		setUserCdo: (state, action) => {
			state.userCdo = action.payload;
		},
		setUserCdoLoanView: (state, action) => {
			state.userCdoLoanView = action.payload;
		},
		setLastUserUpdate: (state, action) => {
			state.lastUserUpdate = action.payload;
		},
		setLimiterEnabled: (state, action) => { 
			state.limiterEnabled = action.payload;
		},
		setLimiterField: (state, action) => { 
			state.limiterField = action.payload;
		},
		setLimiterValue: (state, action) => { 
			state.limiterValue = action.payload;
		},
		setPort: (state, action) => {
			state.port = action.payload;
		},
		setLoanViewTasks: (state, action) => {
			state.loanViewTasks = action.payload;
		},
	},
});

export const { setStandardFields } = encompassSlice.actions;
export const { setUsers } = encompassSlice.actions;
export const { setMe } = encompassSlice.actions;
export const { setUserCdo } = encompassSlice.actions;
export const { setUserCdoLoanView } = encompassSlice.actions;
export const { setLastUserUpdate } = encompassSlice.actions;
export const { setLimiterEnabled } = encompassSlice.actions;
export const { setLimiterField } = encompassSlice.actions;
export const { setLimiterValue } = encompassSlice.actions;
export const { setPort } = encompassSlice.actions;
export const { setLoanViewTasks } = encompassSlice.actions;

export const getStandardFields = (state) => state.encompass.standardFields;
export const getUsers = (state) => state.encompass.users;
export const getMe = (state) => state.encompass.me;
export const getUserCdo = (state) => state.encompass.userCdo;
export const getUserCdoLoanView = (state) => state.encompass.userCdoLoanView;
export const getLastUserUpdate = (state) => state.encompass.lastUserUpdate;
export const getLimiterEnabled = (state) => state.encompass.limiterEnabled;
export const getLimiterField  = (state) => state.encompass.limiterField;
export const getLimiterValue = (state) => state.encompass.limiterValue;
export const getPort = (state) => state.encompass.port;
export const getLoanViewTasks = (state) => state.encompass.loanViewTasks;

export default encompassSlice.reducer;
