import { Storage } from 'aws-amplify';
import store from '../../redux/store';

//Flag this to 
export const getEncompassStandardFields = async (config) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await Storage.get('allFieldsBE11215009.json', {
				download: true,
				expires: 60,
			});
			result.Body.text().then((string) => {
				console.log('response from stand fields', JSON.parse(string))
				resolve(JSON.parse(string));
			});
		} catch (error) {
			reject(error);
		}
	});
};
