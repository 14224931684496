import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: {
			username: '',
			clientId: '',
			email: '',
			phone: '',
			personas: undefined,
			group: '',
			idToken: '',
		},
		encompassToken: '',
		requestedPath: '',
		company: {
			id: '',
			companyName: '',
			accountId: '',
			timezone: '',
			website: '',
			ssoEnabled: false,
			tbwFilterDate: '',
			defaultConfigurationId: '',
			isActive: false,
			LTKTokenExchangeConfig: '',
			TokenExchangeAuth: '',
			TokenExchangeUrl: '',
		},
	},
	reducers: {
		userLogin: (state, action) => {
			state.user.username = action.payload.username;
			state.user.clientId = action.payload.clientId;
			state.user.email = action.payload.email;
			state.user.phone = action.payload.phone;
			state.user.group = action.payload.group;
			state.user.idToken = action.payload.idToken;
		},
		userLogout: (state, action) => {
			state.user.username = '';
			state.user.clientId = '';
			state.user.email = '';
			state.user.phone = '';
			state.user.personas = undefined;
			state.user.group = '';
			state.user.idToken = '';
		},
		setToken: (state, action) => {
			state.user.idToken = action.payload;
		},
		setCompany: (state, action) => {
			state.company.id = action.payload.id;
			state.company.companyName = action.payload.companyName;
			state.company.accountId = action.payload.accountId;
			state.company.timezone = action.payload.timezone;
			state.company.website = action.payload.website;
			state.company.ssoEnabled = action.payload.ssoEnabled;
			state.company.tbwFilterDate = action.payload.tbwFilterDate;
			state.company.defaultConfigurationId = action.payload.defaultConfigurationId;
			state.company.isActive = action.payload.isActive;
			state.company.LTKTokenExchangeConfig = action.payload.LTKTokenExchangeConfig;
			state.company.TokenExchangeAuth = action.payload.TokenExchangeAuth;
			state.company.TokenExchangeUrl = action.payload.TokenExchangeUrl;
		},
		setEncompassToken: (state, action) => {
			state.encompassToken = action.payload;
		},
		setPhone: (state, action) => {
			state.user.phone = action.payload;
		},
		setEmail: (state, action) => {
			state.user.email = action.payload;
		},
		setRequestedPath: (state, action) => {
			state.requestedPath = action.payload;
		},
	},
});

export const {
	userLogin,
	userLogout,
	setToken,
	setCompany,
	setEncompassToken,
	setPhone,
	setEmail,
	setRequestedPath,
} = authSlice.actions;

export const getUser = (state) => state.auth.user;
export const getCompany = (state) => state.auth.company;
export const getToken = (state) => state.auth.user.idToken;
export const getEncompassToken = (state) => state.auth.encompassToken;
export const getRequestedPath = (state) => state.auth.requestedPath;

export default authSlice.reducer;
