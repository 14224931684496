import { createSlice } from '@reduxjs/toolkit';

export const websocketSlice = createSlice({
	name: 'websocket',
	initialState: {
		tokenStatus: 'none', //['pending', 'set', 'refreshing', 'error']
		topic: null,
		ssf: null,
		encryptionKey: null,
		encryptionIv: null,
		encryptedToken: null,
		endpoint: null,
		connectionType: null, //will be set to either ['API', 'MQTT']
		connected: false,
	},
	reducers: {
		updateTokenStatus: (state, action) => {
			state.tokenStatus = action.payload;
		},
		updateSsf: (state, action) => {
			state.ssf = action.payload;
		},
		updateTopic: (state, action) => {
			state.topic = action.payload;
		},
		setSsf: (state, action) => {
			state.ssf = action.payload;
		},
		setEncryptionKey: (state, action) => {
			state.encryptionKey = action.payload;
		},
		setEncryptionIv: (state, action) => {
			state.encryptionIv = action.payload;
		},
		setWsEndpoint: (state, action) => {
			state.endpoint = action.payload;
		},
		setConnectionType: (state, action) => {
			state.connectionType = action.payload;
		},
		updateConnectionStatus: (state, action) => {
			state.connected = action.payload;
		},
	},
});

export const {
	updateTokenStatus,
	updateSsf,
	updateTopic,
	setSsf,
	setEncryptionKey,
	setEncryptionIv,
	setWsEndpoint,
	setConnectionType,
	updateConnectionStatus,
} = websocketSlice.actions;

export const getTokenStatus = (state) => state.websocket.tokenStatus;
export const getSsf = (state) => state.websocket.ssf;
export const getTopic = (state) => state.websocket.topic;
export const getEncryptionKey = (state) => state.websocket.encryptionKey;
export const getEncryptionIv = (state) => state.websocket.encryptionIv;
export const getWsEndpoint = (state) => state.websocket.endpoint;
export const getConnectionType = (state) => state.websocket.connectionType;
export const getConnectionStatus = (state) => state.websocket.connected;

export default websocketSlice.reducer;
