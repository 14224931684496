import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

export const decrypt = (key, iv, encryptedString) => {
	return new Promise((resolve, reject) => {
		try {
			let cryptoKey = Base64.parse(decodeURIComponent(key));
			let cryptoIv = Base64.parse(decodeURIComponent(iv));
			let decryptedBytes = AES.decrypt(encryptedString, cryptoKey, {
				iv: cryptoIv,
			});
			resolve(decryptedBytes.toString(Utf8));
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};
