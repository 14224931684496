import store from '../../redux/store';

//for refactoring, we are looking at taking this concept and moving it to the encompassApi.js

//A function used to wrap the Encompass API calls so token failures can be handled
export async function apiWrapper(fn, args) {
	try {
		return await fn.apply(this, args);
	} catch (e) {
		//check statusCode
		if (e.status && e.status === 401) {
			//Dispatch event to App.js to trigger a new token to be requested.
			if (store.getState().websocket.tokenStatus !== 'refreshing') {
				store.dispatch({ type: 'websocket/updateTokenStatus', payload: 'refreshing' });
			}

			let oldToken = store.getState().websocket.token;
			return new Promise((resolve, reject) => {
				//Wait for a token to be reset
				setTimeout(() => {
					let state = store.getState();
					let tokenStatus = state.websocket.tokenStatus;
					if (tokenStatus !== 'set') {
						reject(
							'Encompass access token has expired. We attempted to obtain a new one but it is either taking longer than expected or another error has occurred. This can be caused if Encompass desktop is not open or active. Please wait and then try the operation again.'
						);
					} else {
						let newToken = state.websocket.token;
						if (oldToken !== newToken) {
							//inject new token to retry
							let oldTokenIndex = args.findIndex((arg) => arg === oldToken);
							args[oldTokenIndex] = newToken;
							//Use IIFE in order to await the function call
							(async () => {
								let retryAttempt = await retry(this, args);
								if (retryAttempt === 'failed') {
									reject(
										'Sorry, an unexpected error has occurred. Please retry the operation.'
									);
								} else {
									resolve(retryAttempt);
								}
								resolve(await retry(this, args));
							})();
						} else {
							reject(
								'The Encompass access token expired but has been reset. Please retry the operation again.'
							);
						}
					}
				}, 2000);
			});
		}
		throw Error(e);
	}
}

async function retry(fn, args) {
	//Could potentially retry multiple times and then fail but maybe that is overkill
	try {
		return await fn.apply(this, args);
	} catch (e) {
		return 'failed';
	}
}
