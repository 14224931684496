import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: {
		// Density can be moved to a DB in the future => AuroraDB
		// This would be more ideal as right now we are setting based on each data grid's
		// density change which means wiring up the event subscription for all data grids.
		//TODO remove event subscription from Data Grids once this is moved to a DB
		// For now to avoid setting all of that up, we'll just cache client side
		dataGridDensity: 'standard', // ['standard', 'compact', 'comfortable']
		sso: false, // Default is false, if set to true, user will be direct to different routes
		ssoIdentity: undefined,
	},
	reducers: {
		setDataGridDensity: (state, action) => {
			state.dataGridDensity = action.payload;
		},
		setSSO: (state, action) => {
			state.sso = action.payload;
		},
		setSSOIdentity: (state, action) => {
			state.ssoIdentity = action.payload;
		},
	},
});

export const { setDataGridDensity, setSSO, setSSOIdentity } = settingsSlice.actions;

export const getDataGridDensity = (state) => state.settings.dataGridDensity;
export const getSSO = (state) => state.settings.sso;
export const getSSOIdentity = (state) => state.settings.ssoIdentity;

export default settingsSlice.reducer;
