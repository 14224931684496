import React, { Suspense } from 'react';

import async from './components/Async';
import lazyLoad from './components/LazyLoad';
import Loader from './components/Loader';
import ErrorBoundary from './components/ErrorBoundary';
import PageErrorBoundary from './components/PageErrorBoundary';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import DocLayout from './layouts/Doc';
import TaskBasedWorkflowDashboard from './layouts/TaskBasedWorkflow';
import BridgeLayout from './layouts/Bridge';
import PowerToolsLayout from './layouts/PowerToolsLayout';

// Guards
import Redirect from './components/Guards/Redirect';
import GuestGuard from './components/Guards/GuestGuard';
import TaskBasedWorkflow from './components/Guards/TaskBasedWorkflow';
import BridgeGuard from './components/Guards/Bridge';
// Guards grant the lowest privilage for that specific route.
// e.g. If the CompanyUser guard is applied then Company Admins, LTK Users, and LTK Admin will also have access to that route
// If the LTKUser guard is applied, only LTK Users and LTK Admin will have access.
import CompanyUser from './components/Guards/CompanyUser';
import CompanyAdmin from './components/Guards/CompanyAdmin';
import LTKUser from './components/Guards/LTKUser';
import LTKAdmin from './components/Guards/LTKAdmin';
// Licensing guards
import ExpertLicenseGuard from './components/Guards/ExpertLicensed';
import OptimizedLicenseGuard from './components/Guards/OptimizedLicensed';

// Auth components
import SignIn from './pages/auth/SignIn';
import SignInSSO from './pages/auth/SignInSSO';
import SSOLanding from './pages/auth/SSOLanding';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import ForgotPassword from './pages/auth/ForgotPasswordPortal';
import ConfirmForgotPassword from './pages/auth/ForgotPasswordConfirm';

// Documentation
import Welcome from './pages/docs/Welcome';
import GettingStarted from './pages/docs/GettingStarted';
import Routing from './pages/docs/Routing';
import Auth0 from './pages/docs/auth/Auth0';
import Cognito from './pages/docs/auth/Cognito';
import Firebase from './pages/docs/auth/Firebase';
import JWT from './pages/docs/auth/JWT';
import Guards from './pages/docs/Guards';
import EnvironmentVariables from './pages/docs/EnvironmentVariables';
import Deployment from './pages/docs/Deployment';
import Theming from './pages/docs/Theming';
import APICalls from './pages/docs/APICalls';
import Internationalization from './pages/docs/Internationalization';
import ESLintAndPrettier from './pages/docs/ESLintAndPrettier';
import MigratingToNextJS from './pages/docs/MigratingToNextJS';
import Support from './pages/docs/Support';
import Changelog from './pages/docs/Changelog';

// Dashboard components
const TBW = async(() => import('./pages/tbw/dashboards/Default'));
const Default = async(() => import('./pages/dashboards/Default'));
const Analytics = async(() => import('./pages/dashboards/Analytics'));
const SaaS = async(() => import('./pages/dashboards/SaaS'));

//Brige components
const BridgeConfigurations = async(() =>
	import('./pages/tools/bridge/BridgeConfig/index')
);
const BridgeTaskLogs = async(() => import('./pages/tools/bridge/BridgeTaskLogs/index'));
const BridgeTasks = async(() => import('./pages/tools/bridge/BridgeTasks/index'));
const BridgeUpdates = async(() => import('./pages/tools/bridge/BridgeUpdates/index'));
const BridgeDataMapping = async(() => import('./pages/tools/bridge/DataMapping/index'));
const BridgeDocumentMapping = async(() =>
	import('./pages/tools/bridge/DocumentMapping/index')
);

//Tools
const BatchFolderMove = async(() => import('./pages/tools/batchFolderMove/index'));
const AutoAssignPro = async(() => import('./pages/tools/autoAssignPro/index'));

// Pipeline components
const Pipeline = async(() => import('./pages/tbw/pages/Pipeline'));
const Loan = async(() => import('./pages/tbw/pages/Loan'));
const Settings = async(() => import('./pages/tbw/pages/Settings'));

//Profile components
const Profile = async(() => import('./pages/profile/Profile'));
const MyCompany = async(() => import('./pages/admin/MyCompany'));
const Calendar = async(() => import('./pages/pages/Calendar'));
const Users = async(() => import('./pages/admin/users/index'));
const Companies = async(() => import('./pages/admin/Companies'));

const routes = [
	{
		//The following uses the Redirect Guard since there an issue with using the / path right now with how it shows up on the sidebar.
		//For the time being, the easiest thing is to avoid exposing a / route and just re-routing to a more appropriate path.
		path: '',
		element: (
			<Redirect>
				<PageErrorBoundary>
					<Suspense fallback={<Loader />}>
						<DashboardLayout />
					</Suspense>
				</PageErrorBoundary>
			</Redirect>
		),
	},
	{
		path: 'dashboard',
		element: (
			<CompanyUser>
				<PageErrorBoundary>
					<Suspense fallback={<Loader />}>
						<DashboardLayout />
					</Suspense>
				</PageErrorBoundary>
			</CompanyUser>
		),
		children: [
			{
				path: '',
				element: <Default />,
			},
		],
	},
	{
		path: 'auth',
		element: (
			<GuestGuard>
				<AuthLayout />
			</GuestGuard>
		),
		children: [
			{
				path: 'sign-in',
				element: <SignIn />,
			},
			{
				path: 'sso',
				element: <SignInSSO />,
			},
			{
				path: 'sso-landing',
				element: <SSOLanding />,
			},
			{
				path: 'forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: 'confirm-forgot-password',
				element: <ConfirmForgotPassword />,
			},
			{
				path: '404',
				element: <Page404 />,
			},
			{
				path: '500',
				element: <Page500 />,
			},
		],
	},
	{
		path: 'admin',
		element: (
			<LTKUser>
				<DashboardLayout />
			</LTKUser>
		),
		children: [
			{
				path: 'companies',
				element: <Companies />,
			},
		],
	},
	{
		path: 'admin',
		element: (
			<CompanyAdmin>
				<DashboardLayout />
			</CompanyAdmin>
		),
		children: [
			{
				path: 'users',
				element: <Users />,
			},
			{
				path: 'my-company',
				element: <MyCompany />,
			},
		],
	},
	{
		path: 'tools',
		element: (
			<ExpertLicenseGuard>
				<PowerToolsLayout />
			</ExpertLicenseGuard>
		),
		children: [
			{
				path: 'batch-folder-move',
				element: <BatchFolderMove />,
			},
			{
				path: 'auto-assign-pro',
				element: <AutoAssignPro />,
			},
		],
	},
	{
		path: 'bridge',
		element: (
			<BridgeGuard>
				<BridgeLayout />
			</BridgeGuard>
		),
		children: [
			{
				path: 'configurations',
				element: <BridgeConfigurations />,
			},
			{
				path: 'logs',
				element: <BridgeTaskLogs />,
			},
			{
				path: 'tasks',
				element: <BridgeTasks />,
			},
			{
				path: 'updates',
				element: <BridgeUpdates />,
			},
			{
				path: 'data-mapping',
				element: <BridgeDataMapping />,
			},
			{
				path: 'document-mapping',
				element: <BridgeDocumentMapping />,
			},
		],
	},
	{
		path: 'profile',
		element: (
			<CompanyUser>
				<DashboardLayout />
			</CompanyUser>
		),
		children: [
			{
				path: 'my-account',
				element: <Profile />,
			},
		],
	},
	{
		path: 'tbw',
		element: (
			<TaskBasedWorkflow>
				<PageErrorBoundary>
					<Suspense fallback={<Loader />}>
						<TaskBasedWorkflowDashboard />
					</Suspense>
				</PageErrorBoundary>
			</TaskBasedWorkflow>
		),
		children: [
			{
				path: 'dashboard',
				element: <TBW />,
			},
			{
				path: 'pipeline',
				element: <Pipeline />,
			},
			{
				path: 'loan',
				element: <Loan />,
			},
			//TODO: Settings Child
			{
				path: 'settings',
				element: <Settings />,
			},
		],
	},
	{
		path: 'documentation',
		element: (
			<CompanyUser>
				<DocLayout />
			</CompanyUser>
		),
		children: [
			{
				path: 'welcome',
				element: <Welcome />,
			},
			{
				path: 'getting-started',
				element: <GettingStarted />,
			},
			{
				path: 'routing',
				element: <Routing />,
			},
			{
				path: 'auth/auth0',
				element: <Auth0 />,
			},
			{
				path: 'auth/cognito',
				element: <Cognito />,
			},
			{
				path: 'auth/firebase',
				element: <Firebase />,
			},
			{
				path: 'auth/jwt',
				element: <JWT />,
			},
			{
				path: 'guards',
				element: <Guards />,
			},
			{
				path: 'environment-variables',
				element: <EnvironmentVariables />,
			},
			{
				path: 'deployment',
				element: <Deployment />,
			},
			{
				path: 'theming',
				element: <Theming />,
			},
			{
				path: 'api-calls',
				element: <APICalls />,
			},
			{
				path: 'internationalization',
				element: <Internationalization />,
			},
			{
				path: 'eslint-and-prettier',
				element: <ESLintAndPrettier />,
			},
			{
				path: 'migrating-to-next-js',
				element: <MigratingToNextJS />,
			},
			{
				path: 'support',
				element: <Support />,
			},
		],
	},
	{
		path: 'changelog',
		element: (
			<CompanyUser>
				<DocLayout />
			</CompanyUser>
		),
		children: [
			{
				path: '',
				element: <Changelog />,
			},
		],
	},
	{
		path: '*',
		element: (
			<CompanyUser>
				<AuthLayout />
			</CompanyUser>
		),
		children: [
			{
				path: '*',
				element: <Page404 />,
			},
		],
	},
];

export default routes;
