import * as React from 'react';
import { withTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';

import { Grid, Hidden, IconButton as MuiIconButton } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';

import NavbarUserDropdown from './NavbarUserDropdown';

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

const Navbar = ({ onDrawerToggle, toggleTheme }) => {
	return (
		<React.Fragment>
			<Grid container alignItems='center'>
				<Hidden mdUp>
					<Grid item>
						{/* <IconButton
							color='inherit'
							aria-label='Open drawer'
							onClick={onDrawerToggle}
							size='large'></IconButton>
						<MenuIcon /> */}
					</Grid>
				</Hidden>
				<Grid item xs />
				<Grid item>
					<NavbarUserDropdown toggleTheme={toggleTheme} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default withTheme(Navbar);
