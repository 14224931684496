import axios from "axios";

const encompassEndpoint = 'https://api.elliemae.com/encompass/v1';
export const getUserCdos = async (user, token) => { 
	//get
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
	try {
		let api = `${encompassEndpoint}/users/${user}/customObjects/`;
		const response = await axios.get(api, { headers });
		return response.data;
	} catch (errors) {
		return errors;
	}
};

export const getUserCdo = async (user, token, name) => { 
	//get
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
	try {
		let api = `${encompassEndpoint}/users/${user}/customObjects/${name}`;
		const response = await axios.get(api, { headers });
		return response.data;
	} catch (errors) {
		return errors;
	}
};

export const createUserCdo = async (user, token, name, object) => {
	let data = btoa(JSON.stringify(object));
	let body = {
		dataObject: data
	};
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
	try {
		let api = `${encompassEndpoint}/users/${user}/customObjects/${name}`;
		const response = await axios.put(api, body, { headers });
		return response.data;
	} catch (errors) {
		return errors;
	};
};

export const updateUserCdo = async (user, token, name, object) => { 
	let data = btoa(JSON.stringify(object));
	let body = {
		dataObject: data
	};
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
	try {
		let api = `${encompassEndpoint}/users/${user}/customObjects/${name}`;
		const response = await axios.patch(api, body, { headers });
		return response.data;
	} catch (errors) {
		return errors;
	};
};

export const deleteUserCdo = async (user, token, name) => { 
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
	};
	try {
		let api = `${encompassEndpoint}/users/${user}/customObjects/${name}`;
		const response = await axios.delete(api, { headers });
		return response.data;
	} catch (errors) {
		return errors;
	}
};
