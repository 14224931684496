import {
	ArrowLeftCircle,
	Key,
	Layers,
	Move,
	Archive,
	Package,
	Activity,
	User,
} from 'react-feather';

// If the user is hitting the portal for TBW purposes, will limit the routes
export const powerToolsItems = [
	{
		title: '',
		pages: [
			{
				href: '/dashboard',
				icon: ArrowLeftCircle,
				title: 'Go Back',
			},
		],
	},
	{
		title: 'Power Tools',
		pages: [
			{
				href: '/tools/batch-folder-move',
				icon: Layers,
				title: 'Batch Folder Move',
			},
			{
				href: '/tools/auto-assign-pro',
				icon: User,
				title: 'Auto-Assign Pro',
			},
			// {
			// 	href: '/bridge/data-mapping',
			// 	icon: Move,
			// 	title: 'Data Mapping Templates',
			// },
			// {
			// 	href: '/bridge/document-mapping',
			// 	icon: Archive,
			// 	title: 'Document Mapping Templates',
			// },
			// {
			// 	href: '/bridge/tasks',
			// 	icon: Package,
			// 	title: 'Tasks',
			// },
			// {
			// 	href: '/bridge/logs',
			// 	icon: Activity,
			// 	title: 'Transactional Logs',
			// },
		],
	},
];
