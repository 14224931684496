import { createMachine, actions, forwardTo } from 'xstate';
import { sendParent } from 'xstate/lib/actions';
import { Auth } from 'aws-amplify';
import { PubSub } from './PubSub';
import { MqttOverWSProvider } from '@aws-amplify/pubsub/lib/Providers';
import { me } from '../encompass/userSettings/me';
import { decrypt } from '../crypto';
import { requestToken } from './requestToken';

const { log, assign } = actions;

async function validateToken(newToken) {
	//check token
	try {
		return await me(newToken).then(() => {
			console.info('token has been validated and set');
			return newToken;
		});
	} catch (error) {
		error.message = 'Token is not valid' || error.message;
		return error;
	}
}

async function getJwtToken() {
	return new Promise(async (resolve, reject) => {
		let cognitoUser = await Auth.currentAuthenticatedUser();
		let jwtToken = cognitoUser.signInUserSession.idToken.jwtToken || '';

		resolve(jwtToken);
	});
}

export const mqttMachine = createMachine(
	{
		id: 'mqttMachine',
		initial: 'plugabble',
		context: {
			mqttTopic: '',
			mqttEncryptionKey: '',
			mqttEncryptionIv: '',
			mqttEndpoint: '',
			errorMessage: '',
			initialTokenRequested: false,
		},
		on: {
			TOKENREQUEST: {
				actions: forwardTo('mqttConnection'),
			},
			//To test killing the machine, comment out the above and use the below and then change one of the API calls so that the token
			//is wrong, e.g. in updateTask.js add on text to the token that is passed to the function so that the API call is rejected
			//since this kicks off the TOKENREQUEST
			//TOKENREQUEST: { target: 'error' },
		},
		states: {
			plugabble: {
				invoke: {
					id: 'addPlugabble',
					src: async (context, event) => {
						let jwtToken = await getJwtToken();
						let mqttProvider = new MqttOverWSProvider({
							//here you would include your token as the query string parameter use to initialize the connection
							aws_pubsub_endpoint: `wss://${context.mqttEndpoint}/mqtt?token=${jwtToken}`,
						});

						PubSub.addPluggable(mqttProvider, 'us-west-1', context.mqttEndpoint);
					},
					onDone: {
						target: 'connect',
					},
				},
			},
			reconnect: {
				invoke: {
					id: 'reconnect',
					src: (context, event) => PubSub.restartMqttOverWSProvider(),
					onDone: {
						target: 'connect',
					},
				},
			},
			connect: {
				invoke: {
					id: 'mqttConnection',
					src: (context, event) => (callback, onReceive) => {
						try {
							let topic = context.mqttTopic;
							let key = context.mqttEncryptionKey;
							let iv = context.mqttEncryptionIv;

							let subscription;

							function disconnect() {
								subscription.unsubscribe();
							}

							onReceive((e) => {
								if (e.type === 'TOKENREQUEST') {
									console.log('Received TOKENREQUEST event in mqttMachine');
									console.log('requesting token from inside MQTT connection');
									PubSub.publish(topic, { type: 'TokenRequest' });
								}
							});

							subscription = PubSub.subscribe(topic, {
								provider: 'MqttOverWSProvider',
							}).subscribe({
								next: async (data) => {
									if (data.value.type) {
										if (data.value.type === 'TokenResponse') {
											console.info('Encrypted token received, attempting to decrypt');
											let encryptedToken = data.value.message;
											let decrypted = await decrypt(key, iv, encryptedToken);
											try {
												let validatedToken = await validateToken(decrypted);
												callback({ type: 'TOKENRECEIVED', payload: validatedToken });
											} catch (error) {
												console.error(error);
												callback({ type: 'ERROR', payload: error });
											}
										} else {
											callback({ type: 'MSGRECEIVED', payload: data.value });
										}
									} else {
										callback({ type: 'MSGRECEIVED', payload: data.value });
									}
								},
								error: async (error) => {
									console.error(error);
									callback({ type: 'RESTART' });
								},
								close: () => {
									console.log('MQTT connection closed');
									callback({ type: 'CLOSE' });
								},
							});

							//If Success:
							console.info('MQTT connection created successfully');
							PubSub.publish(topic, { type: 'DashboardConnected' });
							if (!context.initialTokenRequested) {
								PubSub.publish(topic, { type: 'TokenRequest' });
								callback('INITIALTOKENREQUESTED');
							}

							callback('CONNECTED');

							//Return the disconnect method so the connection can be properly terminated
							//xState will do this automatically once the machine enters new state
							return () => disconnect();
						} catch (error) {
							callback({ type: 'RESTART' });
						}
					},
				},
				on: {
					CONNECTED: {
						actions: sendParent({ type: 'CONNECTED' }),
					},
					INITIALTOKENREQUESTED: {
						actions: assign({ initialTokenRequested: true }),
					},
					TOKENRECEIVED: {
						actions: sendParent((context, event) => ({
							...event,
							type: 'TOKENRECEIVED',
						})),
					},
					MSGRECEIVED: {
						actions: [
							log((context, event) => {
								if (event?.payload?.type !== 'TokenRequest') {
									return 'MQTT message received:';
								}
							}),
							log((context, event) => {
								if (event?.payload?.type !== 'TokenRequest') {
									return event.payload;
								}
							}),
						],
					},
					RESTART: {
						target: 'reconnect',
					},
					ERROR: {
						target: 'error',
						actions: assign({
							errorMessage: (context, event) => (
								event.payload ? event.payload : 'Unkown error: There was no error message returned from the api please contact support'
							),
						}),
					},
					CLOSE: { target: 'close' },
				},
			},
			error: {
				type: 'final',
				data: {
					type: 'error', //send info to appMachine
					errorMessage: (context, event) => (
						context.errorMessage ? context.errorMessage : 'Unkown error: There was no error message returned from the api please contact support'),
				},
			},
			close: {
				type: 'final',
				data: {
					type: 'closed',
				},
			},
		},
	},
	{
		actions: {
			tokenRequest: (context, event) => {
				requestToken(context.mqttTopic, context.mqttEndpoint);
			},
		},
	}
);
