import { Auth } from 'aws-amplify';
import axios from 'axios';
const { REACT_APP_LTK_API_BASE_URL } = process.env;

export const getCompanyInfo = async () => {
	try {
		const signedInUser = await Auth.currentAuthenticatedUser();
		let user = signedInUser;

		//Data return depends on current user state
		if (signedInUser.data) {
			user = signedInUser.data;
		}

		// Get Company ID from Cognito User
		let companyId = user.attributes['custom:companyid'];
		let jwtToken = user.signInUserSession.idToken.jwtToken;

		// Get Company from API
		const headers = {
			Authorization: `Bearer ${jwtToken}`,
		};

		let url = `${REACT_APP_LTK_API_BASE_URL}v1/companies/${companyId}`;
		const companyResponse = await axios.get(url, { headers });
		return companyResponse.data;
	} catch (err) {
		return err;
	}
};
