export const removeGuardedRoutes = (items, user, licenses) => {
	//user is the user redux state
	//group is an array of strings
	const { group } = user;
	let userGroups = [];

	// Based on the user's current group, will add lower groups if applicable.
	// For example, if the user is an LTK Admin, we will add permissions so that they can access
	// ltk-admin, ltk-user, company-admin, and company-user

	if (group.length > 0) {
		userGroups.push(group);
		if (group.includes('ltk-admin')) {
			userGroups.push('ltk-admin');
			userGroups.push('ltk-user');
			userGroups.push('company-admin');
			userGroups.push('company-user');
		} else if (group.includes('ltk-user')) {
			userGroups.push('ltk-user');
			userGroups.push('company-admin');
			userGroups.push('company-user');
		} else if (group.includes('company-admin')) {
			userGroups.push('company-admin');
			userGroups.push('company-user');
		} else if (group.includes('company-user')) {
			userGroups.push('company-user');
		}
	}

	let routes = [];

	try {
		items.map((item) => {
			let addItem = false; //If the item has no pages that need to added, this will remain false and the item will be discarded
			let itemObj = {};
			let pages = [];
			item.pages.map((page) => {
				if (page.children) {
					let addPage = false; //If none of the children need to be added, this will remain false and they will be discarded
					let pageObj = {};
					let children = [];
					page.children.map((child) => {
						if (child.guards) {
							let passesGuard = true;

							if (child.guards.groupGuards) {
								child.guards.groupGuards.forEach((guard) => {
									if (userGroups.length > 0) {
										if (userGroups.indexOf(guard) === -1) {
											passesGuard = false;
										}
									} else {
										passesGuard = false;
									}
								});
							}

							if (child.guards.licenseGuards) {
								child.guards.licenseGuards.forEach((license) => {
									if (licenses) {
										if (licenses.indexOf(license) === -1) {
											passesGuard = false;
										}
									} else {
										passesGuard = false;
									}
								});
							}

							if (passesGuard) {
								addPage = true;
								children.push(child);
							}
						} else {
							//If no guards, safe to add without checking
							addPage = true;
							children.push(child);
						}
					});

					if (addPage) {
						pageObj.href = page.href;
						pageObj.icon = page.icon;
						pageObj.title = page.title;
						pageObj.children = children;

						pages.push(pageObj);
						addItem = true;
					}
				} else {
					if (page.guards) {
						let passesGuard = true;

						if (page.guards.groupGuards) {
							page.guards.groupGuards.forEach((guard) => {
								if (userGroups.length > 0) {
									if (userGroups.indexOf(guard) === -1) {
										passesGuard = false;
									}
								} else {
									passesGuard = false;
								}
							});
						}

						if (page.guards.licenseGuards) {
							page.guards.licenseGuards.forEach((license) => {
								if (licenses) {
									if (licenses.indexOf(license) === -1) {
										passesGuard = false;
									}
								} else {
									passesGuard = false;
								}
							});
						}

						if (passesGuard) {
							addItem = true;
							pages.push(page);
						}
					} else {
						//If no guards, safe to add without checking
						pages.push(page);
						addItem = true;
					}
				}
			});

			if (addItem) {
				itemObj.title = item.title;
				itemObj.pages = pages;
				routes.push(itemObj);
			}
		});

		return routes;
	} catch (err) {
		//TODO
		//Add papertrail support here or something to notify of the issue
		console.error('Error removing guarded routes ', err);
		// if there are any errors, return all items to prevent issues with services not working. There are still route guards applied that will enforce access
		return items;
	}
};
