import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Analytics } from 'aws-amplify';
import config from './aws-exports.js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { inspect } from '@xstate/inspect';

let persistor = persistStore(store);

Amplify.configure(config);

// Analytics.autoTrack('pageView', {
// 	enable: true,
// 	type: 'SPA',
// });

// Analytics.autoTrack('event', {
// 	enable: false,
// });

//Only launch XState inspector in non-production environments
if (process.env.NODE_ENV !== 'production') {
	inspect({
		iframe: false,
	});
}

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);
